import React, { useEffect } from 'react';
import Layout from "../components/layout"
import '../styles/LiveDemo.css';
import { graphql } from "gatsby";
import { DefaultGtag } from '../utils/misc'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Iframe from 'react-iframe'
import YouTubeIcon from '@mui/icons-material/YouTube';
import Collapsible from 'react-collapsible';


const FaqPageStyle = {
    width: "54rem",
    margin: "auto",
    padding: "1rem 2rem",
    marginBottom: "3rem",
    maxWidth: "100%"
    // maxWidth: "calc(100% - 4rem)"
}

const questionStyle = {
    fontSize: "120%",
    fontWeight: "600",
    marginTop: "2rem",
    paddingBottom: "0.25rem"
}

const codeStyle = {
    padding: "0.5rem",
    marginBlock: "0.5rem",
    borderRadius: "4px",
    backgroundColor: "rgba(0,0,0,0.05)",
}

const iframeCtnStyle = {
    background: "rgb(240, 240, 240)",
    display: "flex",
    position: "relative",
    width: "100%",
    maxWidth: "560px",
    maxHeight: "315px",
    height: "315px",
    margin: "0 auto 1rem auto"
}

const youtubeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
}

const PopularPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(function () {
            // need delay, otherwise not working on firefox...
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }, 100);
    }, [])

    return <>
        <Layout>
            <div style={FaqPageStyle}>
                <h1 className="title" style={{ textAlign: "center", color: "#2CB052" }}>FAQ</h1>
                <section style={{ position: "relative", marginBottom: "5rem" }}>
                    <div id="digital-menus" style={{ position: "absolute", top: "-5rem" }}></div>
                    <h2>{t('menus.title')}</h2>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.0.0')}</div>
                    <div>{t('menus.qa.0.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.1.0')}</div>
                    <div>{t('menus.qa.1.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.2.0')}</div>
                    <div>{t('menus.qa.2.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.3.0')}</div>
                    <div>{t('menus.qa.3.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.4.0')}</div>
                    <div>
                        <div>{t('menus.qa.4.1')}</div>
                    </div>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.5.0')}</div>
                    <div>{t('menus.qa.5.1')}</div>
                    <div style={codeStyle}>
                        <code style={{ fontSize: "80%", whiteSpace: "break-spaces" }}>
                            {
                                `<iframe
  src='REPLACE WITH LINK TO YOUR MENU'
  frameborder="0"
  style="display: block"; height: 100%; min-height: 100vh; max-height: 100%; width: 100%"
  height="100%"
  width="100%">
</iframe>`}
                        </code>
                    </div>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.6.0')}</div>
                    <div>{t('menus.qa.6.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.7.0')}</div>
                    <div>{t('menus.qa.7.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.8.0')}</div>
                    <div>{t('menus.qa.8.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('menus.qa.9.0')}</div>
                    <div>{t('menus.qa.9.1')}</div>
                    <div className="topics">
                        <h3>{t('videos')}</h3>
                        {[
                            ["Q7MGqH_krw0", t('menus.videos.0'), "add-items"],
                            ["cytr7U1dins", t('menus.videos.1'), "print"],
                            ["COL5uvpGZ8s", t('menus.videos.2'), "links-qrcode"],
                            ["89CYmtg--f4", t('menus.videos.3'), "feedback"],
                            ["oDMrs3hO5so", t('menus.videos.4'), "update-prices"],
                            ["rGjXGXeyCGU", t('menus.videos.5'), "translate"],
                            ["RDxSHiIC4BY", t('menus.videos.6'), "add-tags"],
                            ["U6nWoFwdQos", t('menus.videos.7'), "activate-ordering-2"],
                            ["XaW_xzbXBhU", t('menus.videos.8'), "whatsapp-notifications-ordering"],
                        ].map(ele =>
                            <Collapsible
                                lazyRender={false}
                                trigger={<div className="name"
                                    style={{ width: "100%", width: "100%", marginBottom: "1rem", background: "rgb(220, 220, 220)", display: "flex", alignItems: "center", padding: "0.5rem", borderRadius: "4px", cursor: "pointer" }}
                                ><AddIcon /> <b>{ele[1]}</b></div>}>
                                <div className="iframe-container" style={iframeCtnStyle}>
                                    <YouTubeIcon className="youtube-icon" style={youtubeStyle} />
                                    <Iframe url={`https://www.youtube.com/embed/${ele[0]}`}
                                        width="100%"
                                        height="100%"
                                        display="initial"
                                        position="relative"
                                        title={ele[1]}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                </div>
                            </Collapsible>
                        )}
                    </div>
                </section>
                <section style={{ position: "relative", marginBottom: "5rem" }}>
                    <div id="website" style={{ position: "absolute", top: "-5rem" }}></div>
                    <h2>Website</h2>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.0.0')}</div>
                    <div>{t('website.qa.0.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.1.0')}</div>
                    <div>{t('website.qa.1.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.2.0')}</div>
                    <div>{t('website.qa.2.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.3.0')}</div>
                    <div>{t('website.qa.3.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.4.0')}</div>
                    <div>{t('website.qa.4.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.5.0')}</div>
                    <div>{t('website.qa.5.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.6.0')}</div>
                    <div>{t('website.qa.6.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.7.0')}</div>
                    <div>{t('website.qa.7.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.8.0')}</div>
                    <div>{t('website.qa.8.1')}</div>
                    <div style={questionStyle}>&#8250;&#160;{t('website.qa.9.0')}</div>
                    <div>{t('website.qa.9.1')}</div><div className="topics">
                        <h3>{t('videos')}</h3>
                        {[
                            ["KVCJeHwyWn0", t('website.videos.0'), "activate-website"],
                            ["aJU4zBMu8pg", t('website.videos.1'), "edit-website"],
                        ].map(ele =>
                            <Collapsible
                                lazyRender={false}
                                trigger={<div className="name"
                                    style={{ width: "100%", width: "100%", marginBottom: "1rem", background: "rgb(220, 220, 220)", display: "flex", alignItems: "center", padding: "0.5rem", borderRadius: "4px", cursor: "pointer" }}
                                ><AddIcon /> <b>{ele[1]}</b></div>}>
                                <div className="iframe-container" style={iframeCtnStyle}>
                                    <YouTubeIcon className="youtube-icon" style={youtubeStyle} />
                                    <Iframe url={`https://www.youtube.com/embed/${ele[0]}`}
                                        width="100%"
                                        height="100%"
                                        display="initial"
                                        position="relative"
                                        title={ele[1]}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                </div>
                            </Collapsible>
                        )}
                    </div>
                </section>
                <section style={{ position: "relative", marginBottom: "5rem" }}>
                    <h2>{t('contact.title')}</h2>
                    <div style={questionStyle}>&#8250;&#160;{t('contact.subtitle')}</div>
                    <div><a rel="nofollow" href="mailto:team@takeaseat.io">&#160;&#160;&#160;team@takeaseat.io</a></div>
                </section>
            </div>
        </Layout>
    </>
}


export const Head = () => <>
  <DefaultGtag />
    {/* <link rel="canonical" href="https://takeaseat.io/faq/" /> */}
    <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/faq/" />
    <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/faq/" />
    <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/faq/" />
    <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/faq/" />
    <title>Frequently Asked Questions (FAQ)</title>
    <meta name="description" content="Frequently Asked Questions (FAQ)" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="The digital partner of restaurants and bars" />
    <meta property="og:description" content="Frequently Asked Questions (FAQ)" />
    <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
    <meta property="og:url" content="https://takeaseat.io/faq/" />
    <meta property="og:site_name" content="Take a Seat" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content="Frequently Asked Questions (FAQ)" />
    <meta name="twitter:description" content="Frequently Asked Questions (FAQ)" />
    <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
    <meta name="twitter:site" content="@Takeaseat_io" />
    <meta name="twitter:creator" content="@Takeaseat_io" />
</>

export default PopularPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","faq", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;